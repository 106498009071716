import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/HA/Gilgamesh`

// BODY
const bodyGilgameshImage = `${imagePath}/Chassis.png`;

// HEADS
const headGilgameshImage = `${imagePath}/heads/Head - Gilgamesh.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headHammerheadImage = `${imagePath}/heads/Head - Hammerhead.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headPaladinImage = `${imagePath}/heads/Head - Paladin.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headVanguardImage = `${imagePath}/heads/Head - Vanguard.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
const weaponsArtilleryImage = `${imagePath}/weapons/Weapon - Artillery.png`;
const weaponsDaisyCutterImage = `${imagePath}/weapons/Weapon - Daisy Cutter.png`;
const weaponsHeavyGatlingImage = `${imagePath}/weapons/Weapon - Heavy Gatling.png`;
const weaponsHeavyMachinegunImage = `${imagePath}/weapons/Weapon - Heavy Machinegun.png`;
const weaponsLegionnaireBattleRifleImage = `${imagePath}/weapons/Weapon - Legionnaire Battle Rifle.png`;
const weaponsSPEARLauncherImage = `${imagePath}/weapons/Weapon - SPEAR Launcher.png`;
const weaponsSuperthermalBladeImage = `${imagePath}/weapons/Weapon - Superthermal Blade.png`;

// GLOWS
const glowSuperthemalBladeImage = `${imagePath}/accessories/Accessories - Glow - Superthermal Blade.png`;

// ACCESSORIES
const accessoryHatchetImage = `${imagePath}/accessories/Accessories - Hatchet.png`;
const accessoryLegionDronesImage = `${imagePath}/accessories/Accessories - Legion Drones.png`;
const accessoryLegionNexusUnderImage = `${imagePath}/accessories/Accessories Under - Legion Nexus.png`;
const accessoryRevolverImage = `${imagePath}/accessories/Accessories - Revolver.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage1 = `${imagePath}/GILGAMESH_ScriptKitt3h.png`;
const coreImage2 = `${imagePath}/GILGAMESH_Fliren.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "36px",
    right: "61px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage1,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "ScriptKitt3h",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage2,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Fliren",
                url: "https://linktr.ee/fliren?utm_source=linktree_profile_share&ltsid=1a59ce3f-a79f-40e0-8035-d8fdd74aa3c4",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "4",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "GILGAMESH",
            foremostSrc: headGilgameshImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "ANGULAR",
            foremostSrc: headAngularImage,
        },
        {
            value: 3,
            label: "BLACKBEARD",
            foremostSrc: headBlackbeardImage,
        },
        {
            value: 4,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 5,
            label: "BRIAR",
            foremostSrc: headBriarImage,
        },
        {
            value: 6,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 7,
            label: "HAMMERHEAD",
            foremostSrc: headHammerheadImage,
        },
        {
            value: 8,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 9,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 10,
            label: "JUDICATOR",
            foremostSrc: headJudicatorImage,
        },
        {
            value: 11,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 12,
            label: "LUDONAUT",
            foremostSrc: headLudonautImage,
        },
        {
            value: 13,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 14,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 15,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 16,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 17,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 18,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 19,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 20,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 21,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 22,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 23,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 24,
            label: "PALADIN",
            foremostSrc: headPaladinImage,
        },
        {
            value: 25,
            label: "PREDATOR",
            foremostSrc: headPredatorImage,
        },
        {
            value: 26,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
        },
        {
            value: 27,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedHairImage,
        },
        {
            value: 28,
            label: "SCARAB",
            foremostSrc: headScarabImage,
        },
        {
            value: 29,
            label: "VANGUARD",
            foremostSrc: headVanguardImage,
        },
        {
            value: 30,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GILGAMESH",
            src: bodyGilgameshImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARTILLERY",
                src: weaponsArtilleryImage,
            },
            {
                value: 1,
                label: "DAISY CUTTER",
                src: weaponsDaisyCutterImage,
            },
            {
                value: 2,
                label: "HEAVY GATLING",
                src: weaponsHeavyGatlingImage,
            },
            {
                value: 3,
                label: "HEAVY MACHINEGUN",
                src: weaponsHeavyMachinegunImage,
            },
            {
                value: 4,
                label: "LEGIONNAIRE BATTLE RIFLE",
                src: weaponsLegionnaireBattleRifleImage,
            },
            {
                value: 5,
                label: "SPEAR LAUNCHER",
                src: weaponsSPEARLauncherImage,
            },
            {
                value: 6,
                label: "SUPERTHERMAL BLADE",
                src: weaponsSuperthermalBladeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SUPERTHERMAL BLADE",
            frontSrc: glowSuperthemalBladeImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "HATCHET",
            src: accessoryHatchetImage,
        },
        {
            value: 1,
            label: "LEGION DRONES",
            frontSrc: accessoryLegionDronesImage,
        },
        {
            value: 2,
            label: "LEGION NEXUS",
            backmostSrc: accessoryLegionNexusUnderImage,
        },
        {
            value: 3,
            label: "REVOLVER",
            src: accessoryRevolverImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
