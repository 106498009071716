import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/HA/Gilgamesh-Drones`

// BODY
const bodyLegionDrone1Image = `${imagePath}/Legion Drone 1.png`;
const bodyLegionDrone2Image = `${imagePath}/Legion Drone 2.png`;
const bodyLegionDrone3Image = `${imagePath}/Legion Drone 3.png`;
const bodyLegionDroneAllImage = `${imagePath}/Legion Drones All.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "36px",
    right: "61px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "3",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "LEGION DRONE 1",
            src: bodyLegionDrone1Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "LEGION DRONE 2",
            src: bodyLegionDrone2Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "LEGION DRONE 3",
            src: bodyLegionDrone3Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "LEGION DRONES ALL",
            src: bodyLegionDroneAllImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
    ],
    labels: {
        "head": "EMPTY",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
